import './Nav.css';
import { Link } from 'react-router-dom';

import watsapp from './img/Whatsapp.png';
import telegram from './img/Telegram.png';
import github from './img/Github.png'

function Nav({ burger, widthBrouser, setNeon }) {
    return (
        <div className="nav">

            <div className="nav__ul-wrapper">
                <Link to="/" onClick={() => { burger(); setNeon(true) }}>
                    <svg className='nav__logo' xmlns="http://www.w3.org/2000/svg" width="90" height="54" viewBox="0 0 90 54" fill="none">

                        <path fillRule="evenodd" clipRule="evenodd" d="M84.8552 52.7724L87.3351 53.9997V9.8694C87.3351 6.37785 84.0369 3.81463 80.6312 4.65672L45.186 14.7535L0.612061 2.4541L7.5579 6.92277L45.1965 17.3143L81.2662 7.03972C83.0928 6.61181 84.8552 7.99074 84.8552 9.8694V52.7724Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M45.6278 22.9909L7.80188 12.5655C4.35903 11.6166 0.953369 14.1922 0.953369 17.7412V54L3.43322 52.7481V17.7412C3.43322 15.8211 5.27676 14.4272 7.13919 14.9405C7.13918 14.9405 7.13919 14.9405 7.13919 14.9405L45.7129 25.572L46.4455 25.3177C48.1479 24.7267 57.0169 22.3282 65.6434 20.0332C67.5114 19.5362 69.3579 19.0468 71.103 18.5852L81.6517 13.2597C81.6517 13.2597 49.2608 21.7298 45.6278 22.9909Z" fill="white" />
                        <path d="M14.1699 49.925C14.1699 51.3874 13.3208 52.6887 12.2383 52.6887H7.40918V43.0156H12.2383C13.2725 43.0156 14.1418 44.2478 14.1699 45.7794V49.925ZM13.2041 45.7794C13.2041 45.0193 12.7695 44.3975 12.2383 44.3975H8.375V51.3068H12.2383C12.7695 51.3068 13.2041 50.685 13.2041 49.925V45.7794Z" fill="#FFFDFD" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.34692 42.9541H12.238C12.7817 42.9541 13.2744 43.2778 13.632 43.788C13.99 44.2987 14.2174 45.0018 14.2317 45.7783L14.2317 45.7795L14.2317 49.9251C14.2317 50.6674 14.0163 51.3707 13.6611 51.89C13.3061 52.4089 12.8063 52.7504 12.238 52.7504H7.34692V42.9541ZM7.47092 43.0773V52.6272H12.238C12.7523 52.6272 13.2183 52.3181 13.5585 51.8207C13.8985 51.3238 14.1077 50.6451 14.1077 49.9251V45.78C14.0937 45.0252 13.8724 44.3466 13.5303 43.8585C13.1877 43.3697 12.7287 43.0773 12.238 43.0773H7.47092ZM8.31275 44.336H12.238C12.5298 44.336 12.7882 44.5067 12.9711 44.7684C13.1541 45.0303 13.2659 45.3883 13.2659 45.7795V49.9251C13.2659 50.3162 13.1541 50.6742 12.9711 50.9362C12.7882 51.1978 12.5298 51.3685 12.238 51.3685H8.31275V44.336ZM8.43674 44.4592V51.2453H12.238C12.4775 51.2453 12.702 51.1052 12.8692 50.8659C13.0363 50.6269 13.1419 50.294 13.1419 49.9251V45.7795C13.1419 45.4105 13.0363 45.0777 12.8692 44.8387C12.702 44.5993 12.4775 44.4592 12.238 44.4592H8.43674Z" fill="#FFFDFD" />
                        <path d="M16.9705 47.1612H21.7996V48.5431H16.9705V51.3068H22.7654V52.6887H16.0046V43.0156H22.7654V44.3975H16.9705V47.1612Z" fill="#FFFDFD" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.9426 42.9541H22.8274V44.4592H17.0324V47.0997H21.8616V48.6048H17.0324V51.2453H22.8274V52.7504H15.9426V42.9541ZM16.0666 43.0773V52.6272H22.7034V51.3685H16.9085V48.4816H21.7376V47.2229H16.9085V44.336H22.7034V43.0773H16.0666Z" fill="#FFFDFD" />
                        <path d="M24.2139 45.7794V43.0156H25.1797V45.4454L27.2643 51.3068H27.9202L30.0048 45.4454V43.0156H30.9706V45.7794L28.5078 52.6887H26.6727L24.2139 45.7794Z" fill="#FFFDFD" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M24.1519 42.9541H25.2417V45.4349L27.3081 51.2453H27.8764L29.9428 45.4349V42.9541H31.0326V45.79L28.5516 52.7504H26.6288L24.1519 45.79V42.9541ZM24.2758 43.0773V45.7689L26.7165 52.6272H28.4639L30.9086 45.7689V43.0773H30.0668V45.4561L27.964 51.3685H27.2204L25.1177 45.4561V43.0773H24.2758Z" fill="#FFFDFD" />
                        <path d="M33.583 47.1612H38.4121V48.5431H33.583V51.3068H39.378V52.6887H32.6172V43.0156H39.378V44.3975H33.583V47.1612Z" fill="#FFFDFD" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M32.5552 42.9541H39.4399V44.4592H33.645V47.0997H38.4741V48.6048H33.645V51.2453H39.4399V52.7504H32.5552V42.9541ZM32.6792 43.0773V52.6272H39.3159V51.3685H33.521V48.4816H38.3501V47.2229H33.521V44.336H39.3159V43.0773H32.6792Z" fill="#FFFDFD" />
                        <path d="M42.0818 51.3068H47.8767V52.6887H41.116V43.0156H42.0818V51.3068Z" fill="#FFFDFD" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M41.0544 42.9541H42.1443V51.2453H47.9392V52.7504H41.0544V42.9541ZM42.0823 51.3069H42.0203V43.0773H41.1784V52.6272H47.8152V51.3685H42.0823V51.3069Z" fill="#FFFDFD" />
                        <path d="M55.7969 49.925C55.7969 51.445 54.9277 52.6887 53.8653 52.6887H50.9678C49.8973 52.6887 49.0361 51.4565 49.0361 49.925V45.7794C49.0361 44.2593 49.9054 43.0156 50.9678 43.0156H53.8653C54.8874 43.0156 55.7969 44.2708 55.7969 45.7794V49.925ZM54.8311 45.7794C54.8311 45.0193 54.3965 44.3975 53.8653 44.3975H50.9678C50.4366 44.3975 50.002 45.0193 50.002 45.7794V49.925C50.002 50.685 50.4366 51.3068 50.9678 51.3068H53.8653C54.3965 51.3068 54.8311 50.685 54.8311 49.925V45.7794Z" fill="#FFFDFD" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M49.6545 43.8627C49.3124 44.3522 49.0981 45.0305 49.0981 45.7795V49.9251C49.0981 50.6799 49.3104 51.3582 49.6514 51.8461C49.9925 52.3341 50.4584 52.6272 50.9678 52.6272H53.8652C54.3703 52.6272 54.8362 52.3316 55.1785 51.8418C55.5206 51.3523 55.7349 50.674 55.7349 49.9251V45.7795C55.7349 45.037 55.511 44.3586 55.1636 43.8673C54.8157 43.3752 54.3495 43.0773 53.8652 43.0773H50.9678C50.4627 43.0773 49.9968 43.3729 49.6545 43.8627ZM49.5526 43.7924C49.9105 43.2803 50.4104 42.9541 50.9678 42.9541H53.8652C54.4031 42.9541 54.9027 43.2839 55.2651 43.7965C55.6281 44.3099 55.8589 45.0134 55.8589 45.7795V49.9251C55.8589 50.6962 55.6385 51.3997 55.2804 51.9121C54.9225 52.4242 54.4226 52.7504 53.8652 52.7504H50.9678C50.4067 52.7504 49.9067 52.4274 49.5496 51.9164C49.1924 51.4053 48.9741 50.7017 48.9741 49.9251V45.7795C48.9741 45.0083 49.1945 44.3048 49.5526 43.7924ZM50.2347 44.7684C50.4176 44.5067 50.676 44.336 50.9678 44.336H53.8652C54.157 44.336 54.4154 44.5067 54.5983 44.7684C54.7813 45.0303 54.8931 45.3883 54.8931 45.7795V49.9251C54.8931 50.3162 54.7813 50.6742 54.5983 50.9362C54.4154 51.1978 54.157 51.3685 53.8652 51.3685H50.9678C50.676 51.3685 50.4176 51.1978 50.2347 50.9362C50.0517 50.6742 49.9399 50.3162 49.9399 49.9251V45.7795C49.9399 45.3883 50.0517 45.0303 50.2347 44.7684ZM50.3366 44.8387C50.1695 45.0777 50.0639 45.4105 50.0639 45.7795V49.9251C50.0639 50.294 50.1695 50.6269 50.3366 50.8659C50.5038 51.1052 50.7283 51.2453 50.9678 51.2453H53.8652C54.1047 51.2453 54.3292 51.1052 54.4964 50.8659C54.6635 50.6269 54.7691 50.294 54.7691 49.9251V45.7795C54.7691 45.4105 54.6635 45.0777 54.4964 44.8387C54.3292 44.5993 54.1047 44.4592 53.8652 44.4592H50.9678C50.7283 44.4592 50.5038 44.5993 50.3366 44.8387Z" fill="#FFFDFD" />
                        <path d="M64.3931 47.1612C64.3931 48.6237 63.544 49.925 62.4614 49.925H58.5982V52.6887H57.6323V43.0156H62.4614C63.4957 43.0156 64.3649 44.2478 64.3931 45.7794V47.1612ZM63.4273 45.7794C63.4273 45.0193 62.9926 44.3975 62.4614 44.3975H58.5982V48.5431H62.4614C62.9926 48.5431 63.4273 47.9213 63.4273 47.1612V45.7794Z" fill="#FFFDFD" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M57.5703 42.9541H62.4614C63.005 42.9541 63.4978 43.2778 63.8554 43.788C64.2134 44.2987 64.4408 45.0018 64.4551 45.7783L64.4551 45.7795L64.4551 47.1613C64.4551 47.9037 64.2397 48.607 63.8845 49.1263C63.5295 49.6451 63.0297 49.9867 62.4614 49.9867H58.6601V52.7504H57.5703V42.9541ZM57.6943 43.0773V52.6272H58.5361V49.8634H62.4614C62.9757 49.8634 63.4417 49.5543 63.7819 49.057C64.1219 48.56 64.3311 47.8814 64.3311 47.1613V45.78C64.3171 45.0252 64.0958 44.3466 63.7537 43.8585C63.4111 43.3697 62.952 43.0773 62.4614 43.0773H57.6943ZM58.5361 44.336H62.4614C62.7532 44.336 63.0116 44.5067 63.1945 44.7684C63.3775 45.0303 63.4893 45.3883 63.4893 45.7795V47.1613C63.4893 47.5524 63.3775 47.9105 63.1945 48.1724C63.0116 48.434 62.7532 48.6048 62.4614 48.6048H58.5361V44.336ZM58.6601 44.4592V48.4816H62.4614C62.7009 48.4816 62.9254 48.3414 63.0926 48.1021C63.2597 47.8631 63.3653 47.5302 63.3653 47.1613V45.7795C63.3653 45.4105 63.2597 45.0777 63.0926 44.8387C62.9254 44.5993 62.7009 44.4592 62.4614 44.4592H58.6601Z" fill="#FFFDFD" />
                        <path d="M67.0969 47.1612H71.926V48.5431H67.0969V51.3068H72.8919V52.6887H66.1311V43.0156H72.8919V44.3975H67.0969V47.1612Z" fill="#FFFDFD" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M66.0691 42.9541H72.9539V44.4592H67.1589V47.0997H71.988V48.6048H67.1589V51.2453H72.9539V52.7504H66.0691V42.9541ZM66.1931 43.0773V52.6272H72.8299V51.3685H67.0349V48.4816H71.864V47.2229H67.0349V44.336H72.8299V43.0773H66.1931Z" fill="#FFFDFD" />
                        <path d="M80.707 52.6887L78.7754 49.925H75.5962V52.6887H74.6304V43.0156H79.4595C80.4736 43.0156 81.3911 44.2478 81.3911 45.7794V47.1612C81.3911 48.3358 80.8519 49.401 80.047 49.7868L81.3911 51.7099V52.6887H80.707ZM80.4253 45.7794C80.4253 45.0193 79.9907 44.3975 79.4595 44.3975H75.5962V48.5431H79.4595C79.9907 48.5431 80.4253 47.9213 80.4253 47.1612V45.7794Z" fill="#FFFDFD" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M74.5684 42.9541H79.4595C79.993 42.9541 80.4926 43.2777 80.8562 43.7876C81.2203 44.2983 81.4531 45.0019 81.4531 45.7795V47.1613C81.4531 48.3191 80.9357 49.3858 80.1385 49.8097L81.4531 51.6907V52.7504H80.6746L78.7429 49.9867H75.6582V52.7504H74.5684V42.9541ZM74.6924 43.0773V52.6272H75.5342V49.8634H78.8078L80.7394 52.6272H81.3291V51.7293L79.9547 49.7627L80.0201 49.7314C80.7961 49.3594 81.3291 48.3217 81.3291 47.1613V45.7795C81.3291 45.0254 81.1032 44.3472 80.755 43.8589C80.4063 43.3698 79.9401 43.0773 79.4595 43.0773H74.6924ZM75.5342 44.336H79.4595C79.7512 44.336 80.0097 44.5067 80.1925 44.7684C80.3756 45.0303 80.4873 45.3883 80.4873 45.7795V47.1613C80.4873 47.5524 80.3756 47.9105 80.1925 48.1724C80.0097 48.434 79.7512 48.6048 79.4595 48.6048H75.5342V44.336ZM75.6582 44.4592V48.4816H79.4595C79.6989 48.4816 79.9234 48.3414 80.0907 48.1021C80.2577 47.8631 80.3633 47.5302 80.3633 47.1613V45.7795C80.3633 45.4105 80.2577 45.0777 80.0907 44.8387C79.9234 44.5993 79.6989 44.4592 79.4595 44.4592H75.6582Z" fill="#FFFDFD" />
                        <path d="M35.397 37.7274C36.3585 37.7274 37.1433 37.1446 37.1433 36.4364V29.9814H38.8896V36.4364C38.8896 37.8049 37.3528 39.0184 35.3929 39.0184H33.6466C32.5988 39.0184 31.7236 38.7233 31.0251 38.1405C30.3266 38.7233 29.4514 39.0184 28.4036 39.0184H26.6573C24.7878 39.0184 23.1606 37.8971 23.1606 36.4364V29.9814H24.9069V36.4364C24.9069 37.1483 25.6958 37.7274 26.6532 37.7274H28.3995C29.361 37.7274 30.1458 37.1446 30.1458 36.4364V29.9814H31.8921V36.4364C31.8921 37.1483 32.681 37.7274 33.6384 37.7274H35.397Z" fill="white" />
                        <path d="M42.8261 33.8544H51.5657V35.1454H42.8261V37.7274H53.3161V39.0184H41.0757V29.9814H53.312V31.2724H42.822V33.8544H42.8261Z" fill="white" />
                        <path d="M55.1484 39.0184V29.9814H63.8881C65.7248 29.9814 67.3848 31.1323 67.3848 32.5634C67.3848 33.338 66.9821 33.9835 66.1973 34.4999C66.9821 35.0163 67.3848 35.6618 67.3848 36.4364C67.3848 37.8307 65.848 38.9815 63.8881 39.0184H55.1484ZM56.8988 33.8544H63.8922C64.8537 33.8544 65.6385 33.2753 65.6385 32.5634C65.6385 31.8552 64.8496 31.2724 63.8922 31.2724H56.8988V33.8544ZM56.8988 35.1454V37.7274H63.8922C64.8537 37.7274 65.6385 37.1446 65.6385 36.4364C65.6385 35.7282 64.8496 35.1454 63.8922 35.1454H56.8988Z" fill="white" />

                        <defs>
                            <clipPath id="clip0_67272_543">
                                <rect width="89.9646" height="54" fill="white" />
                            </clipPath>
                        </defs>
                    </svg></Link>

                <ul className='nav__ul'>
                    <li><Link to="/About" className='nav__ul-link' onClick={() => burger()}>Обо мне</Link></li>
                    <li><Link to="/Skills" className='nav__ul-link' onClick={() => burger()}>Skills</Link></li>
                    <li><Link to="/Lending" className='nav__ul-link' onClick={() => burger()}>Лейдинг</Link></li>
                    <li><Link to="/Multipage" className='nav__ul-link' onClick={() => burger()}>Многостраничник</Link></li>
                    <li><Link to="/Eshop" className='nav__ul-link' onClick={() => burger()}>Интернет магазин</Link></li>
                    <li><Link to="/ReactPage" className='nav__ul-link' onClick={() => burger()}>React - приложения</Link></li>
                </ul>
            </div>

            <div className="nav__contact">
                <a href="tel:+79279268009" className="nav__contact-tel">8-927-926-80-09</a>

                <div className="nav__contact-social">
                    <a href={(widthBrouser > 1023) ? "https://web.whatsapp.com/send?phone=+79279268009" : "whatsapp://send?phone=+79279268009"} target='blank'><img src={watsapp} alt="watsapp" /></a>
                    <a href="https://t.me/Mringer13" target='blank'><img src={telegram} alt="telegram" /></a>
                    <a href="https://github.com/Maredin" target='blank'><img src={github} alt="github" target='blank' /></a>
                </div>
            </div>


        </div>
    )
}

export default Nav;